import getConfig from "next/config";
import { ImageLoader } from "next/image";
const { publicRuntimeConfig } = getConfig();

const imgProxyLoader: ImageLoader = ({ src, width, quality }) => {
  // If it starts with s3, it's already a full URL
  if (src.startsWith("https://s3")) {
    const maxWidth = Math.min(640, width);
    return `${publicRuntimeConfig.IMGPROXY_BASEURL}/unsigned/rs:fill:${maxWidth}:::/g:ce/plain/${src}@webp`;
  }
  return src;
};

export default imgProxyLoader;
