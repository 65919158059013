import { NextPage } from "next/types";

import { AppHead, AppHeadProps } from "@/components/generic/app/AppHead";
import LayoutEmpty from "@/components/layout/LayoutEmpty";
import { Error404 } from "@/components/pages/errors/Error404";

type Props = {
  appHead?: AppHeadProps;
};

const Error404Page: NextPage<Props> = () => {
  return (
    <>
      <AppHead title="404 Error Page not found" nofollow={true} noindex={true} />;
      <LayoutEmpty>
        <Error404 />
      </LayoutEmpty>
    </>
  );
};

export default Error404Page;
