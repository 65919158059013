import getConfig from "next/config";
import Image from "next/image";
const { publicRuntimeConfig } = getConfig();

// Use the prop types from the existing Image component
type AppImageProps = React.ComponentProps<typeof Image>;

const AppImage: React.FC<AppImageProps> = ({ src, ...props }) => {
  let imgSrc = src;
  // If src is string
  if (publicRuntimeConfig.ASSETS_PREFIX) {
    // If src is relative, prepend the publicRuntimeConfig static folder
    if (typeof src === "string" && src.startsWith("/static/")) {
      imgSrc = `${publicRuntimeConfig.ASSETS_PREFIX}${src}`;
    }
  }
  // eslint-disable-next-line jsx-a11y/alt-text
  return <Image src={imgSrc} {...props} />;
};

export default AppImage;
