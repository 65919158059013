import { cva, type VariantProps } from "class-variance-authority";
import NextLink, { LinkProps } from "next/link";
import * as React from "react";

import { cn } from "@/core/utils/styles/cn";

const linkVariations = cva("", {
  variants: {
    variant: {
      default: "",
      button:
        "h-10 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap font-semibold rounded-xl ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 bg-primary-600 text-white hover:bg-primary-700 active:bg-primary-900/90 hover:no-underline",
      buttonDisabled:
        "h-10 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap font-semibold rounded-xl ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 bg-primary-600 text-white hover:bg-primary-700 active:bg-primary-900/90 hover:no-underline pointer-events-none opacity-50",
      buttonOutline:
        "h-10 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap font-semibold rounded-xl ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 border border-slate-200 bg-white text-neutral-700 hover:bg-slate-100 hover:text-slate-900 active:bg-slate-200 shadow-sm hover:no-underline",
      buttonOutlineDisabled:
        "h-10 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap font-semibold rounded-xl ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 border border-slate-200 bg-white text-neutral-700 hover:bg-slate-100 hover:text-slate-900 active:bg-slate-200 shadow-sm hover:no-underline pointer-events-none opacity-50",
    },
    size: {
      default: "",
      sm: "h-8 px-3 text-sm",
      lg: "h-12 px-6 text-lg",
      icon: "h-10 w-10 py-0 px-0",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

export interface AnchorProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof linkVariations> {}

const Link = React.forwardRef<HTMLAnchorElement, AnchorProps & LinkProps>(
  ({ className, variant, size, children, ...props }, ref) => {
    return (
      <NextLink {...props} legacyBehavior>
        <a className={cn(linkVariations({ variant, size, className }))} ref={ref}>
          {children}
        </a>
      </NextLink>
    );
  },
);
Link.displayName = "Link";

const ExternalLink = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  ({ className, variant, size, children, ...props }, ref) => {
    return (
      <a className={cn(linkVariations({ variant, size, className }))} ref={ref} {...props}>
        {children}
      </a>
    );
  },
);
ExternalLink.displayName = "ExternalLink";

export { Link, ExternalLink, linkVariations };
